import { render, staticRenderFns } from "./EditClause.vue?vue&type=template&id=12b5f3c4&scoped=true&"
import script from "./EditClause.vue?vue&type=script&lang=js&"
export * from "./EditClause.vue?vue&type=script&lang=js&"
import style0 from "./EditClause.vue?vue&type=style&index=0&id=12b5f3c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12b5f3c4",
  null
  
)

export default component.exports